@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input:focus,
  .StripeElement--focus {
    @apply ring-2 ring-blue-600;
  }
}

@layer utilities {
  .table-container-border-rounded {
    border-radius: 8px;
    border-color: #dfebf4;
    border-collapse: collapse;
    border-spacing: 0;
    border-style: solid;
    border-width: thin;
    overflow: hidden;
  }

  .table-container-border-rounded td:first-child,
  .table-container-border-rounded th:first-child {
    border-left-style: hidden;
  }
  .table-container-border-rounded td:last-child,
  .table-container-border-rounded th:last-child {
    border-right-style: hidden;
  }
  .table-container-border-rounded tr:first-child th {
    border-top-style: hidden;
  }

  .table-container-border-rounded tr:last-child td {
    border-bottom-style: hidden;
  }

  .scroll-snap-align-end {
    scroll-snap-type: inline;
  }

  .scroll-snap-align-end > div:last-child {
    scroll-snap-align: end;
  }

  .active-tab {
    border-bottom-color: white;
  }

  .active-tab span {
    @apply text-lns-gray-text;
  }

  .-mb-1px {
    margin-bottom: -1px;
  }
}
